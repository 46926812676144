<template>
  <div class="home-top">
    <div class="header">
      <div class="header-left">
        <img class="title-logo" src="@/assets/logo.jpg" alt="" />
        <div class="title">平江县中小企业梯度培育服务平台</div>
      </div>
      <div class="header-right" v-if="!userInfo.access_token">
        <router-link to="/login">
          <el-button type="text">登录</el-button>
        </router-link>
        <el-divider direction="vertical"></el-divider>
        <router-link to="/register">
          <el-button type="text">注册</el-button>
        </router-link>
      </div>
      <div class="header-right" v-else>
        欢迎你
        <el-popover placement="bottom" width="10" trigger="click">
          <div class="popover-list">
            <div>
              <el-button type="text" @click="editPassword">修改密码</el-button>
            </div>
            <div>
              <el-button type="text" @click="loginOut">退出登录</el-button>
            </div>
          </div>
          <span class="user" slot="reference">{{ userInfo.nick_name }}</span>
        </el-popover>
      </div>
    </div>
    <nav class="header-nav">
      <router-link to="/">
        <span>首页</span>
      </router-link>
      <template v-if="!userInfo.access_token">
        <!-- <span @click="goDown('video')">培训视频</span> -->
        <span @click="goDown('new')">最新资讯</span>
        <span @click="goDown('trends')">人才培训</span>
      </template>
      <template v-else>
        <router-link
          :to="{ name: `${item.code}`, query: { id: `${item.id}` } }"
          v-for="item of topMenu"
          :key="item.id"
        >
          <span>{{ item.name }}</span>
        </router-link>
      </template>
    </nav>
    <!-- 修改密码 -->
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible"
      width="30%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div class="register-from-box">
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item
            label="旧密码："
            prop="oldPassword"
            :rules="stringRule()"
          >
            <el-input
              placeholder="请输入"
              v-model="form.oldPassword"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="新密码："
            prop="newPassword"
            :rules="passwordRule()"
          >
            <el-input
              placeholder="请输入"
              v-model="form.newPassword"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="再次输入密码："
            prop="newPassword1"
            :rules="validatorRule(validatePass2)"
          >
            <el-input
              placeholder="请输入"
              v-model="form.newPassword1"
              type="password"
            ></el-input>
          </el-form-item>
          <el-form-item class="register-footer" label-width="0">
            <el-button type="primary" class="register-btn" @click="handleSubmit"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import formValidate from '@/mixins/form-validate';
import { clearStore, getStore } from '@/util/store';
import { mapGetters } from 'vuex';
import { updatePassword } from '@/api/system/user';
import md5 from 'crypto-js/md5';
import { calcDate } from '@/util/date';
import { validatenull } from '@/util/validate';
import website from '@/config/website';
export default {
  mixins: [formValidate],
  data() {
    return {
      form: {},
      codeTitle: '获取验证码',
      btnLoading: false,
      reciprocal: 60,
      timer: 0,
      //刷新token锁
      refreshLock: false,
      //刷新token的时间
      refreshTime: '',
      dialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(['userInfo', 'topMenu']),
  },
  created() {
    clearInterval(this.refreshTime);
    //实时检测刷新token
    this.refreshToken();
  },
  methods: {
    // 定时检测token
    refreshToken() {
      this.refreshTime = setInterval(() => {
        const token =
          getStore({
            name: 'token',
            debug: true,
          }) || {};
        const date = calcDate(token.datetime, new Date().getTime());
        if (validatenull(date)) return;
        if (date.seconds >= website.tokenTime && !this.refreshLock) {
          this.refreshLock = true;
          this.$store
            .dispatch('refreshToken')
            .then(() => {
              this.refreshLock = false;
            })
            .catch(() => {
              this.refreshLock = false;
            });
        }
      }, 10000);
    },
    // 打开修改密码窗口
    editPassword() {
      this.dialogVisible = true;
    },
    // 倒计时
    reciprocalFn() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.reciprocal--;
        if (this.reciprocal <= 0) {
          this.reciprocal = 60;
          this.codeTitle = '获取验证码';
        } else {
          this.codeTitle = `${this.reciprocal} 秒`;
          this.reciprocalFn();
        }
      }, 1000);
    },
    validatePass2(param, rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    //跳转到指定栏目
    goDown(selectID) {
      let toElement = document.getElementById(selectID);
      if (toElement != null) {
        toElement.scrollIntoView(true);
      } else {
        this.$router.push({ path: '/' }, () => {
          setTimeout(() => {
            this.goDown(selectID);
          }, 500);
        });
      }
    },
    //修改密码
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '修改中,请稍后。。。',
            spinner: 'el-icon-loading',
          });
          updatePassword(
            md5(this.form.oldPassword),
            md5(this.form.newPassword),
            md5(this.form.newPassword1)
          ).then(
            () => {
              this.$message({
                type: 'success',
                message: '操作成功!',
              });
              loading.close();
              this.$router.push('login');
            },
            (error) => {
              loading.close();
              console.log(error);
            }
          );
        }
      });
    },
    // 退出登录
    loginOut() {
      this.$confirm('是否退出, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          clearStore({ type: 1 });
          this.$store.dispatch('LogOut').then(() => {
            this.$router.push({ path: '/login' });
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.popover-list {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.register-from-box {
  .register-item {
    display: flex;
  }
  .register-footer {
    ::v-deep .el-form-item__content {
      display: flex;
      justify-content: center;
    }
    .register-btn {
      width: 160px;
    }
  }
  .el-form {
    font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
    font-weight: 700;
    font-style: normal;
  }
}
.home-top {
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 33px 360px;
    box-sizing: border-box;
    .header-left {
      display: flex;
      justify-content: center;
      align-items: center;
      > img {
        width: 64px;
        height: 64px;
        margin-right: 20px;
      }
      > div {
        font-weight: 700;
        font-style: normal;
        font-size: 36px;
      }
    }
    .header-right {
      .el-button {
        font-size: 18px;
      }
      .user {
        cursor: pointer;
        color: #227ce5;
      }
    }
  }
  .header-nav {
    width: 100%;
    padding: 20px 360px;
    box-sizing: border-box;
    background-color: #227ce5;
    color: white;
    display: flex;
    align-items: center;
    span {
      padding: 0 20px;
      cursor: pointer;
      color: white;
      &:hover {
        font-weight: bolder;
      }
    }
  }
}
</style>
