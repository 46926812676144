<template>
  <div class="home">
    <home-header />
    <div class="home-main">
      <!-- banner -->
      <div
        class="home-main-box carousel"
        v-loading="imgLoading"
        element-loading-text="信息加载中请稍等"
      >
        <el-carousel height="450px">
          <el-carousel-item v-for="item in imgList" :key="item.id">
            <el-image
              class="carousel-img"
              @click="clickBanner(item)"
              :src="item.picUrl"
              fit="fill"
            ></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 最新资讯 -->
      <div
        class="home-main-box latest-news"
        id="new"
        v-loading="newLoading"
        element-loading-text="信息加载中请稍等"
      >
        <div class="main-header">
          <div class="header-left">
            <div class="icons"></div>
            <div class="title">最新资讯</div>
          </div>
          <div class="header-right">
            <el-radio-group v-model="activeTab" @change="changeTab">
              <el-radio-button label="1">通知公告</el-radio-button>
              <el-radio-button label="4">工作动态</el-radio-button>
              <el-radio-button label="2">国家政策</el-radio-button>
              <el-radio-button label="3">区域政策</el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <div class="list-box">
          <div class="list-item" v-for="item of newList" :key="item.id">
            <div class="item-left">
              <div class="item-icon"></div>
              <div class="item-content">
                {{ item.title }}
              </div>
              <el-button
                @click="openInformation(item.id, isHeader)"
                class="item-btn"
                type="text"
                >[ 详情 ]</el-button
              >
            </div>
            <div class="item-right">{{ getDate(item.publishDate) }}</div>
          </div>
          <div class="list-item item-block"></div>
        </div>
        <div class="new-footer">
          <router-link :to="`/newsList?type=${activeTab}`">
            <el-button type="text"
              >更多<i class="el-icon-arrow-right"></i
            ></el-button>
          </router-link>
        </div>
      </div>
      <!-- 培训动态 -->
      <div
        class="home-main-box training-trends"
        id="trends"
        v-loading="trendsLoading"
        element-loading-text="信息加载中请稍等"
      >
        <div class="main-header">
          <div class="header-left">
            <div class="icons"></div>
            <div class="title">人才培训</div>
          </div>
          <div class="header-right">
            <router-link to="/trendsList">
              <el-button type="text"
                >更多<i class="el-icon-arrow-right"></i
              ></el-button>
            </router-link>
          </div>
        </div>
        <div class="trends-list">
          <div class="trends-item" v-for="item of trends" :key="item.id">
            <el-image :src="item.thumbnail" fit="fill"></el-image>
            <div class="trends-content">
              <div>
                {{ item.title }}
              </div>
              <div>{{ delHtmlTag(item.content) }}</div>
              <div class="content-footer">
                <div>发布日期：{{ getDate(item.publishDate) }}</div>
                <el-button type="text" @click="openInformation(item.id)"
                  >详情<i class="el-icon-arrow-right"></i
                ></el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 培训视频 -->
      <!-- <div
        class="home-main-box training-video"
        id="video"
        v-loading="videoLoading"
        element-loading-text="信息加载中请稍等"
      >
        <div class="main-header">
          <div class="header-left">
            <div class="icons"></div>
            <div class="title">培训视频</div>
          </div>
          <div class="header-right">
            <router-link to="/videoList">
              <el-button type="text"
                >更多<i class="el-icon-arrow-right"></i
              ></el-button>
            </router-link>
          </div>
        </div>
        <div class="video-list">
          <div class="video-item" v-for="item of videoList" :key="item.id">
            <el-image :src="item.thumbnail"></el-image>
            <el-button class="video-btn" type="warning" @click="openVideo(item)"
              >立即学习</el-button
            >
            <div class="video-title">
              {{ item.title }}
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <web-footer />
    <!-- 视频详情 -->
    <video-info :visible="videoVisable" :info="activeRow" @close="close" />
  </div>
</template>

<script>
import {
  getInformation,
  getTrendList,
  getVideoList,
  getBanner,
  // getInformationInfo,
} from '@/api/home';
import webFooter from '@/components/webFooter.vue';
import HomeHeader from '@/components/homeHeader.vue';
import { getToken } from '@/util/auth';
import { dateFormat } from '@/util/date';
import VideoInfo from './home/videoInfo.vue';
import { getDetail } from '@/api/informationRelease/information';
export default {
  components: { webFooter, HomeHeader, VideoInfo },
  name: 'HomeView',
  data() {
    return {
      videoVisable: false,
      activeRow: {},
      activeTab: '1',
      videoList: [{}, {}, {}, {}, {}, {}, {}, {}],
      trends: [],
      newList: [],
      imgList: [
        {
          picUrl: '/img/banner.png',
        },
      ],
      imgLoading: false,
      newLoading: false,
      trendsLoading: false,
      videoLoading: false,
    };
  },
  computed: {
    isHeader() {
      return this.activeTab != 1;
    },
  },
  mounted() {
    this.getBanner();
    this.getTrendList();
    this.getVideoList();
    this.getInformation(1);
  },
  methods: {
    // 清除html标签
    delHtmlTag(str) {
      return str ? str.replace(/<[^>]+>/g, '') : '';
    },
    // 获取日期
    getDate(date) {
      return dateFormat(new Date(date), 'yyyy年MM月dd日');
    },
    // 关闭视频页面
    close() {
      this.videoVisable = false;
    },
    // 打开视频页面
    openVideo(row) {
      if (!getToken()) {
        this.$router.push('login');
        return;
      }
      this.activeRow = row;
      this.videoVisable = true;
    },
    // 获取视频详情
    getVideoInfo(row) {
      if (!getToken()) {
        this.$router.push('login');
        return;
      }
      getDetail(row.contentId).then(({ data: res }) => {
        this.activeRow = res.data;
        this.videoVisable = true;
      });
    },
    // 点击banner图
    clickBanner(row) {
      switch (row.linkType) {
        // 1.政策性文件
        case 1:
          this.openInformation(row.contentId, true);
          break;
        // 4.企业人才培训
        //2.企业通知
        case 2:
        case 4:
          this.openInformation(row.contentId);
          break;
        // 3.培训视频
        case 3:
          this.getVideoInfo(row);
          break;
      }
    },
    // 打开详情
    /**
     *
     */
    openInformation(id, isHeader = false) {
      this.$router.push({
        name: 'infoView',
        query: { id, isHeader },
      });
    },
    // 获取banner
    getBanner() {
      this.imgLoading = true;
      getBanner().then(
        ({ data: res }) => {
          this.imgLoading = false;
          if (res.data.length) {
            this.imgList.splice(0, this.imgList.length, ...res.data);
          }
        },
        (error) => {
          this.imgLoading = false;
          console.log(error);
        }
      );
    },
    // 切换tab获取不同的最新资讯
    changeTab(val) {
      this.getInformation(val);
    },
    //获取最新资讯
    getInformation(tagType) {
      this.newLoading = true;
      getInformation({ tagType }).then(
        ({ data: res }) => {
          this.newLoading = false;
          this.newList.splice(0, this.newList.length, ...res.data);
        },
        (error) => {
          this.newLoading = false;
          console.log(error);
        }
      );
    },
    // 获取培训动态
    getTrendList() {
      this.trendsLoading = true;
      getTrendList().then(
        ({ data: res }) => {
          this.trendsLoading = false;
          this.trends.splice(0, this.trends.length, ...res.data);
        },
        (error) => {
          this.trendsLoading = false;
          console.log(error);
        }
      );
    },
    // 获取培训视频
    getVideoList() {
      this.videoLoading = true;
      getVideoList().then(
        ({ data: res }) => {
          this.videoLoading = false;
          this.videoList.splice(0, this.videoList.length, ...res.data);
        },
        (error) => {
          this.videoLoading = false;
          console.log(error);
        }
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  min-height: 100%;
  background-color: #f2f2f2;
  ::v-deep .el-radio-button__inner {
    background-color: #f2f2f2;
  }

  .home-main {
    padding: 0 360px;
    box-sizing: border-box;
    .home-main-box {
      width: 100%;
      padding: 20px;
      background-color: white;
      margin: 20px 0;
    }
    .carousel {
      .carousel-img {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    .main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header-right {
        ::v-deep .el-radio-button__inner {
          border: none;
        }
      }
      .header-left {
        display: flex;
        align-items: center;
        .icons {
          width: 12px;
          height: 36px;
          background-color: #227ce5;
        }
        .title {
          font-family: 'Arial Negreta', 'Arial Normal', 'Arial';
          font-weight: 700;
          font-style: normal;
          font-size: 24px;
          margin-left: 10px;
        }
      }
    }
    .latest-news {
      .list-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 20px;
        .item-block {
          flex: none !important;
          width: 551px;
        }
        .list-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex: 1;
          margin-right: 20px;
          .item-left {
            flex: 2;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .item-icon {
              width: 14px;
              height: 14px;
              border: 1px #797979 solid;
              border-radius: 14px;
            }
            .item-content {
              width: 310px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              padding: 0 10px;
            }
          }
          .item-right {
            flex: 1;
            text-align: right;
            // margin-left: 30px;
          }
        }
      }
      .new-footer {
        text-align: right;
      }
    }
    .training-trends {
      .trends-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 20px;
        .trends-item {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 20px;
          .el-image {
            width: 180px;
            height: 141px;
            margin-right: 20px;
          }
          .trends-content {
            width: 340px;
            > div {
              margin: 10px;
              &:nth-of-type(1) {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
              &:nth-of-type(2) {
                word-break: break-all;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }
            .content-footer {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }
        }
      }
    }

    .video-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 20px;
      .video-item {
        position: relative;
        width: 276px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        .el-image {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
        .video-title {
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 10px;
          color: white;
          background-color: rgba(0, 0, 0, 0.32);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          box-sizing: border-box;
        }
        .video-btn {
          position: relative;
          z-index: 1;
        }
      }
    }
  }
}
</style>
