export default {
  state: {
    isShowFullScreenBtn: false,
    isFullScreen: false,
    importUrl: '',
    whiteList: {},
  },
  mutations: {
    setWhiteList(state, data) {
      state.whiteList[data] = true;
    },
    setShowFullScreenBtn(state, data) {
      state.isShowFullScreenBtn = data;
    },
    setIsFullScreen(state, data) {
      state.isFullScreen = data;
    },
  },
};
