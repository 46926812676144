import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import store from '@/store/';

Vue.use(VueRouter);

const routes = [
  { path: '*', component: HomeView },
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '@/views/AboutView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/webLogin.vue'),
  },
  {
    path: '/forgetPassword',
    name: 'forgetPassword',
    component: () => import('@/views/forgetPassword.vue'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/webRegister.vue'),
  },
  {
    path: '/businessManagement',
    name: 'businessManagement',
    component: () => import('@/views/businessManagement/indexView.vue'),
  },
  {
    path: '/enterprise',
    name: 'enterprise',
    component: () => import('@/views/enterprise/indexView.vue'),
  },
  {
    path: '/newsList',
    name: 'newsList',
    component: () => import('@/views/home/latestNewsList.vue'),
  },
  {
    path: '/trendsList',
    name: 'trendsList',
    component: () => import('@/views/home/trainingTrendsList.vue'),
  },
  {
    path: '/videoList',
    name: 'videoList',
    component: () => import('@/views/home/trainingVideoList.vue'),
  },
  {
    path: '/infoView',
    name: 'infoView',
    component: () => import('@/views/home/infoView.vue'),
  },
  {
    path: '/questionView',
    name: 'questionView',
    component: () =>
      import(/* webpackChunkName: "page" */ '@/views/home/questionView.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, form, next) => {
  const arr = [
    'login',
    'home',
    'register',
    'forgetPassword',
    'newsList',
    'trendsList',
    'videoList',
    'infoView',
    'questionView',
    'h5',
  ];
  if (!arr.includes(to.name) && !store.getters.userInfo.access_token) {
    next({ name: 'login' });
  } else {
    next();
  }
});

export default router;
