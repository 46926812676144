import request from '@/util/axios';

export const getInformation = (params = {}) =>
  request({
    url: '/api/blade-home/getNewsData',
    method: 'get',
    params: {
      ...params,
    },
  });

export const getInformationList = (current, size, params = {}) =>
  request({
    url: '/api/blade-home/getNewsPage',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  });

export const getTrendList = () =>
  request({
    url: '/api/blade-home/getPersonnelTrainingData',
    method: 'get',
  });

export const getTrendPage = (current, size) =>
  request({
    url: '/api/blade-home/getPerTrainPage',
    method: 'get',
    params: {
      current,
      size,
    },
  });

export const getVideoList = () =>
  request({
    url: '/api/blade-home/getVideosData',
    method: 'get',
  });

export const getVideoPage = (current, size) =>
  request({
    url: '/api/blade-home/getVideosPage',
    method: 'get',
    params: {
      current,
      size,
    },
  });

  export const getQuestmanagePage = (current, size) =>
    request({
      url: '/api/blade-home/getQuestmanagePage',
      method: 'get',
      params: {
        current,
        size,
      },
    });

export const getBanner = () =>
  request({
    url: '/api/blade-home/getBanners',
    method: 'get',
  });

export const getInformationInfo = (id) => {
  return request({
    url: '',
    method: 'get',
    params: {
      id,
    },
  });
};

export const getTrendInfo = (id) => {
  return request({
    url: '',
    method: 'get',
    params: {
      id,
    },
  });
};
