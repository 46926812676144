<template>
  <el-dialog
    :title="info.title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="70%"
    :before-close="handleClose"
  >
    <div class="main">
      <v-video :url="info.attachment" />
    </div>
  </el-dialog>
</template>

<script>
import vVideo from '@/components/vVideo.vue';
export default {
  components: { vVideo },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  watch: {
    visible: {
      handler: function (params) {
        this.dialogVisible = params;
      },
      immediate: true,
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
}
</style>
