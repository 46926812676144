<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view />
  </div>
</template>

<script>
import { isMobile } from './util/util';
export default {
  data() {
    return {};
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (isMobile() || screen.availWidth < 500) {
        window.location.href = `/h5/index.html`;
      }
    },
  },
};
</script>
<style lang="scss">
body,
html,
#app {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
</style>
