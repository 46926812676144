import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import '@/assets/scss/element-variables.scss';
import Avue from '@smallwei/avue';
import axios from '@/util/axios';
import VueAxios from 'vue-axios';
import VueTinymce from '@packy-tang/vue-tinymce';
import '@smallwei/avue/lib/index.css';
import VueVideoPlayer from 'vue-video-player';
import './rem';
import 'video.js/dist/video-js.css';
import VcChart from 'vc-chart';
// import 'video.js/dist/lang/zh-CN.js';

import basicContainer from './components/basic-container';

Vue.config.productionTip = false;

Vue.use(VcChart);
Vue.use(VueVideoPlayer);
Vue.use(ElementUI);
Vue.use(VueAxios, axios);
Vue.use(VueTinymce);
Vue.use(Avue, { axios });

// 注册全局容器
Vue.component('basicContainer', basicContainer);

const vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

window.videojs = VueVideoPlayer.videojs;
window._vue = vue;
