const getters = {
  token: (state) => state.user.token,
  roles: (state) => state.user.roles,
  permission: (state) => state.user.permission,
  menu: (state) => state.user.menu,
  topMenu: (state) => state.user.topMenu,
  menuId: (state) => state.user.menuId,
  menuAll: (state) => state.user.menuAll,
  userInfo: (state) => state.user.userInfo,
  topActive: (state) => state.extended.topActive,
  leftActive: (state) => state.extended.leftActive,
};
export default getters;
