import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user';
import extended from './modules/extended';
import getters from './getters';
import fullScreen from './modules/fullScreen';

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    user,
    extended,
    fullScreen,
  },
  getters,
});

export default store;
