/**
 * 全局配置文件
 */
export default {
  key: 'enterprise', //配置主键,目前用于存储
  clientId: 'saber', // 客户端id
  clientSecret: 'saber_secret', // 客户端密钥
  captchaMode: true, // 是否开启验证码模式
  lockPage: '/lock',
  tokenTime: 3000,
  tokenHeader: 'Blade-Auth',
  //配置菜单的属性
  menu: {
    iconDefault: 'iconfont icon-caidan',
    props: {
      label: 'name',
      path: 'path',
      icon: 'source',
      children: 'children',
    },
  },
  //http的status默认放行列表
  statusWhiteList: [],
};
