export const calcDate = (date1, date2) => {
  let date3 = date2 - date1;

  let days = Math.floor(date3 / (24 * 3600 * 1000));

  let leave1 = date3 % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
  let hours = Math.floor(leave1 / (3600 * 1000));

  let leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
  let minutes = Math.floor(leave2 / (60 * 1000));

  let leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
  let seconds = Math.round(date3 / 1000);
  return {
    leave1,
    leave2,
    leave3,
    days: days,
    hours: hours,
    minutes: minutes,
    seconds: seconds,
  };
};

/**
 * 日期格式化
 */
export function dateFormat(date, format) {
  format = format || "yyyy-MM-dd hh:mm:ss";
  if (date !== "Invalid Date") {
    let o = {
      "M+": date.getMonth() + 1, //month
      "d+": date.getDate(), //day
      "h+": date.getHours(), //hour
      "m+": date.getMinutes(), //minute
      "s+": date.getSeconds(), //second
      "q+": Math.floor((date.getMonth() + 3) / 3), //quarter
      S: date.getMilliseconds(), //millisecond
    };
    if (/(y+)/.test(format))
      format = format.replace(
        RegExp.$1,
        (date.getFullYear() + "").substr(4 - RegExp.$1.length)
      );
    for (let k in o)
      if (new RegExp("(" + k + ")").test(format))
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length === 1
            ? o[k]
            : ("00" + o[k]).substr(("" + o[k]).length)
        );
    return format;
  }
  return "";
}

/**
 * 当前时间戳
 */
export function dateNow() {
  return dateFormat(new Date(), "yyyyMMddhhmmss");
}

/**
 * 计算两个日期相差天数
 */
export function intervalDay(date1, date2) {
  var time1 = new Date(date1).getTime();
  var time2 = new Date(date2).getTime();
  let time3 = time2 - time1;
  return Math.floor(time3 / (24 * 3600 * 1000)) + 1;
}

/**
 * 获取指定月份的第一天和最后一天
 * @param year 年份
 * @param month 月份
 * @param num 加减月份的数字
 */
export function getDateOfMonth(date, num = 0) {
  if (!date) {
    return [];
  }

  let firstDay = new Date(date.setMonth(date.getMonth() + num));
  const currentMonth = firstDay.getMonth(); // 获取月份天数
  let lastDay = new Date(firstDay.getFullYear(), currentMonth + 1, 0);

  firstDay = dateFormat(new Date(firstDay.setDate(1)), "yyyy-MM-dd");
  lastDay = dateFormat(lastDay, "yyyy-MM-dd");

  return [`${firstDay} 00:00:00`, `${lastDay} 00:00:00`];
}
