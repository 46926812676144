<template>
  <div class="footer">
    <div class="footer-left">
      <!-- <img class="footer-logo" src="@/assets/logo.png" alt="" />
      <img class="footer-logo" src="@/assets/logo.png" alt="" /> -->
    </div>
    <div class="footer-center">
      <div>主办单位：平江县中小企业公共服务平台</div>
      <div>地址：平江县碧潭路100号科技和工业信息化局5楼</div>
      <div>邮编：410400</div>
      <div>联系电话：0730-6261860</div>
      <!-- <div>备案号：湘ICP备10xxxx84号</div> -->
      <!-- <div>技术支持：易元数字技术（广东）有限公司</div> -->
      <!-- <div>湘公网安备 430XXXXXX30号</div> -->
      <div>
        为了获得更好的用户体验，建议您使用FireFox、360浏览器极速模式、Google
        Chrome、Edge ，分辨率1920x1080及以上浏览本网站
      </div>
    </div>
    <div class="footer-right">
      <div>
        <!-- <img class="footer-logo" src="@/assets/logo.png" alt="" />
        <div class="title">公众号</div> -->
      </div>
      <div>
        <img class="footer-logo" src="@/assets/code.jpg" alt="" />
        <div class="title">小程序</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer {
  flex: 1.2;
  position: relative;
  background-color: #227ce5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 50px 15%;
  font-size: 15px;
  .footer-center {
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
    > div {
      margin-right: 20px;
      line-height: 30px;
    }
  }
  .footer-left {
    display: flex;
    img {
      width: 44%;
      margin-left: 10px;
    }
  }
  .footer-right {
    display: flex;
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
    }
    img {
      width: 50%;
    }
  }
}
</style>
