const extended = {
  state: {
    topActive: '0',
    leftActive: '0',
  },
  mutations: {
    setTopActive(state, data) {
      state.topActive = data;
    },
    setLeftActive(state, data) {
      state.leftActive = data;
    },
  },
};

export default extended;
