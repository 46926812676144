import request from '@/util/axios';

export const getList = (current = 1, size = 10, params = {}) => {
  return request({
    url: '/api/blade-business/infopublish/page',
    method: 'get',
    params: {
      ...params,
      current,
      size,
    },
  });
};

export const getOffShelfInfo = (id) => {
  return request({
    url: '/api/blade-business/infopublish/offShelfInfo',
    method: 'post',
    params: {
      id,
    },
  });
};

export const reviewSubmit = (row) => {
  return request({
    url: '/api/blade-business/infopublish/approveInfo',
    method: 'post',
    params: { ...row },
  });
};

export const remove = (ids) => {
  return request({
    url: '/api//blade-business/infopublish/remove',
    method: 'post',
    params: {
      ids,
    },
  });
};

export const add = (row) => {
  return request({
    url: '/api/blade-business/infopublish/save',
    method: 'post',
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: '/api/blade-business/infopublish/update',
    method: 'post',
    data: row,
  });
};

export const getDetail = (id) => {
  return request({
    url: '/api/blade-business/infopublish/detail',
    method: 'get',
    params: {
      id,
    },
  });
};

export const batchConfirm = (ids) => {
  return request({
    url: '/api/blade-business/infopublish/batchConfirm',
    method: 'post',
    params: {
      ids,
    },
  });
};

export const batchSubmit = (ids, row) => {
  return request({
    url: '/api/blade-business/infopublish/batch-submit',
    method: 'post',
    params: { ids },
    data: row,
  });
};
