import {
  isPositiveNumber,
  isPhone,
  isEmail,
  isvalidatemobile,
  cardid,
  validateURL,
  isPositiveInteger,
  validatenumord,
  isNumber,
  validateLowerCase,
} from '@/util/validate';

const validPositiveNumber = (param, rule, value, callback) => {
  if (!value && !param.status) {
    return callback();
  }

  const data = isPositiveNumber(value, param.length);

  if (data.result && value) {
    return callback(new Error(data.msg));
  }

  if (value < param.min) {
    return callback(new Error(`允许输入的最小值为${param.min}`));
  }

  if (value > param.max) {
    return callback(new Error(`允许输入的最大值为${param.max}`));
  }
  callback();
};
const validPositiveInteger = (param, rule, value, callback) => {
  if (!value && param.status) {
    return callback();
  }

  if (!isPositiveInteger(value) && value) {
    return callback(new Error('请输入正整数'));
  }

  if (value < param.min) {
    return callback(new Error(`允许输入的最小值为${param.min}`));
  }

  if (value > param.max) {
    return callback(new Error(`允许输入的最大值为${param.max}`));
  }
  callback();
};
const validPhone = (param, rule, value, callback) => {
  if (!param.status && !value) {
    return callback();
  }
  if (!isPhone(value)) {
    return callback(new Error('请输入正确的电话号码'));
  }
  callback();
};
const validIdCode = (param, rule, value, callback) => {
  if (!param.status && !value) {
    callback();
  }
  const data = cardid(value);
  if (data.result) {
    return callback(new Error(data.msg));
  }
  callback();
};
const validateEmail = (param, rule, value, callback) => {
  if (!param.status && !value) {
    callback();
  }
  if (!isEmail(value)) {
    return callback(new Error('请填写正确的邮箱地址'));
  }
  callback();
};
const validateMobile = (param, rule, value, callback) => {
  if (!param.status && !value) {
    callback();
  }
  const data = isvalidatemobile(value);
  if (data.result) {
    return callback(new Error(data.msg));
  }
  callback();
};
const validSelect = (param, rule, value, callback) => {
  value = value || '';
  let length =
    Object.prototype.toString.call(value) === '[object Array]'
      ? value.length
      : value.split(',').length;

  if (!param.status && !length) {
    return callback();
  }

  if (param.type === 'max') {
    if (length > param.num) {
      return callback(new Error(`最多选择${param.num}项`));
    }
  }

  if (param.type === 'min') {
    if (length < param.num) {
      return callback(new Error(`至少选择${param.num}项`));
    }
  }

  callback();
};
const validatePassword = (rule, value, callback) => {
  if (value === '') {
    return callback(new Error('请输入密码'));
  }

  if (value.length > 16) {
    return callback(new Error('请输入8-16位,包括大写字母,小写字母,数字'));
  }

  let pPattern = /^.*(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,16}/;

  if (!pPattern.test(value)) {
    return callback(new Error('请输入8-16位,包括大写字母,小写字母,数字'));
  }

  callback();
};
const validateUrl = (param, rule, value, callback) => {
  if (!param.status && !value) {
    callback();
  }
  if (!validateURL(value)) {
    return callback(new Error('请填写正确的url地址'));
  }
  callback();
};
const validNumber = (param, rule, value, callback) => {
  if (!value && !param.status) {
    return callback();
  }

  const data = isNumber(value, param.length);

  if (data.result && value) {
    return callback(new Error(data.msg));
  }

  if (value < param.min) {
    return callback(new Error(`允许输入的最小值为${param.min}`));
  }

  if (value > param.max) {
    return callback(new Error(`允许输入的最大值为${param.max}`));
  }
  callback();
};
const validDecimals = (param, rule, value, callback) => {
  if (!value && !param.status) {
    return callback();
  }

  const data = validatenumord(value, param.length);

  if (data.result && value) {
    return callback(new Error(data.msg));
  }

  if (value < param.min) {
    return callback(new Error(`允许输入的最小值为${param.min}`));
  }

  if (value > param.max) {
    return callback(new Error(`允许输入的最大值为${param.max}`));
  }
  callback();
};
const validateLowerCaseData = (param, rule, value, callback) => {
  if (!value && !param.status) {
    return callback();
  }

  if (!validateLowerCase(value)) {
    return callback(new Error('请填写小写字母'));
  }

  callback();
};

const validArray = (rule, value, callback) => {
  window._vue.$nextTick(() => {
    if (!value) {
      return callback(new Error('必填项不能为空'));
    }

    if (!value.length) {
      return callback(new Error('必填项不能为空'));
    }

    callback();
  });
};
export default {
  methods: {
    /**
     * 设置为必填项
     */
    required() {
      return [
        {
          required: true,
          message: '必填项不能为空',
          trigger: 'change',
        },
      ];
    },
    requiredBlur() {
      return [
        {
          required: true,
          message: '必填项不能为空',
          trigger: 'blur',
        },
      ];
    },
    /**
     * 必填项（数组类型）
     */
    requiredArray() {
      return [
        {
          required: true,
          validator: validArray,
          trigger: 'blur',
        },
      ];
    },
    lowerCaseRule(status = true) {
      return [
        {
          required: status,
          message: '必填项不能为空',
          trigger: 'change',
        },
        {
          validator: validateLowerCaseData.bind(this, { status }),
          trigger: 'change',
        },
      ];
    },
    /**
     * 校验身份证
     * @param {boolean} status 设置是否为必填项
     */
    idCardRule(status = true) {
      return [
        {
          required: status,
          message: '必填项不能为空',
          trigger: 'change',
        },
        {
          validator: validIdCode.bind(this, { status }),
          trigger: 'change',
        },
      ];
    },
    /**
     * 校验是否为正数
     * @param {boolean} status 设置是否为必填
     * @param {number} min 设置最小值
     * @param {number} max 设置最大值
     * @param {number} length 设设置允许输入小数点后几位
     */
    positiveNumberRule(status = true, min = 0, max = 999, length = 2) {
      return [
        {
          required: status,
          message: '必填项不能为空',
          trigger: 'change',
        },
        {
          validator: validPositiveNumber.bind(this, {
            status,
            length,
            min,
            max,
          }),
          trigger: 'change',
        },
      ];
    },
    /**
     * 校验下拉框选项
     * @param {boolean} status 设置是否必填
     * @param {string} type 设置选择类型 支持输入 'max' 和 'min'
     * @param {number} num  设置选中的数量
     */
    selectRule(status = true, type = 'max', num = 99) {
      return [
        {
          required: status,
          message: '必选项不能为空',
          trigger: 'change',
        },
        {
          validator: validSelect.bind(this, {
            status,
            type,
            num,
          }),
          trigger: 'change',
        },
      ];
    },
    /**
     * 校验正整数
     * @param {boolean} status 设置是否必填
     * @param {number} min 设置最小值
     * @param {number} max 设置最大值
     */
    positiveIntegerRule(status = true, min = 0, max = 999) {
      return [
        {
          required: status,
          message: '必填项不能为空',
          trigger: 'change',
        },
        {
          validator: validPositiveInteger.bind(this, {
            status,
            min,
            max,
          }),
          trigger: 'change',
        },
      ];
    },

    /**
     * 校验小数
     * @param {boolean} status 设置是否必填
     * @param {number} min 设置最小值
     * @param {number} max 设置最大值
     * @param {number} length 设置允许输入小数点后几位
     */
    decimalsRule(status = true, min = -999, max = 999, length = 2) {
      return [
        {
          required: status,
          message: '必填项不能为空',
          trigger: 'change',
        },
        {
          validator: validDecimals.bind(this, {
            status,
            length,
            min,
            max,
          }),
          trigger: 'change',
        },
      ];
    },
    /**
     * 校验字符串
     * @param {boolean} status 设置是否必填
     * @param {number} min 设置最小长度
     * @param {number} max 设置最大长度
     */
    stringRule(status = true, min = 1, max = 30) {
      return [
        {
          required: status,
          message: '必填项不能为空',
          trigger: 'change',
        },
        {
          min,
          max,
          message: `请输入长度为${min}到${max}`,
          trigger: 'change',
        },
      ];
    },
    /**
     * 校验数字
     * @param {boolean} status 设置是否必填
     * @param {number} min 设置最小值
     * @param {number} max 设置最大值
     * @param {number} length 设置允许输入小数点后几位
     */
    numberRule(status = true, min = -999, max = 999, length = 2) {
      return [
        {
          required: status,
          message: '必填项不能为空',
          trigger: 'change',
        },
        {
          validator: validNumber.bind(this, {
            status,
            length,
            min,
            max,
          }),
          trigger: 'change',
        },
      ];
    },
    /**
     * 校验电话号
     * @param {boolean} status 设置是否必填
     */
    phoneRule(status = true) {
      return [
        {
          required: status,
          message: '必填项不能为空',
          trigger: 'change',
        },
        {
          validator: validPhone.bind(this, { status }),
          trigger: 'change',
        },
      ];
    },
    /**
     * 校验邮箱
     * @param {boolean} status 设置是否必填
     */
    emailRule(status = true) {
      return [
        {
          required: status,
          message: '必填项不能为空',
          trigger: 'change',
        },
        {
          validator: validateEmail.bind(this, { status }),
          trigger: 'change',
        },
      ];
    },
    /**
     * 校验手机号
     * @param {boolean} status 设置是否必填
     */
    mobileRule(status = true) {
      return [
        {
          required: status,
          message: '必填项不能为空',
          trigger: 'change',
        },
        {
          validator: validateMobile.bind(this, { status }),
          trigger: 'change',
        },
      ];
    },
    /**
     * 校验url
     * @param {boolean} status 设置是否必填
     */
    urlRule(status = true) {
      return [
        {
          required: status,
          message: '必填项不能为空',
          trigger: 'change',
        },
        {
          validator: validateUrl.bind(this, { status }),
          trigger: 'change',
        },
      ];
    },
    /**
     * 校验密码
     */
    passwordRule() {
      return [
        {
          required: true,
          message: '必填项不能为空',
          trigger: 'change',
        },
        {
          validator: validatePassword,
          trigger: 'change',
        },
      ];
    },

    /**
     * 自定义校验
     * @param {function} validator 自定义校验的方法
     * @param {boolean} status 设置是否必填
     * @returns
     */
    validatorRule(validator, status = true) {
      return [
        {
          required: status,
          message: '必填项不能为空',
          trigger: 'change',
        },
        {
          validator: validator.bind(this, { status }),
          trigger: 'change',
        },
      ];
    },
  },
};
